<template>
  <div class="add-initial-contract">
    <div class="add-initial-contract__content" v-loading="loading">
      <div>
        <div class="add-initial-contract__title">
          Создание изначального договора
        </div>
        <div
          class="form-input"
          :class="{
            'form-input--full' : form.ClientId,
            'form-input--error' : errors.ClientId
          }"
          @click="openDialogAddingInitialClient('ClientId')"
        >
          <input :value="form.ClientId && form.ClientId.Name" type="text" readonly>
          <span class="form-input__label">Рекламодатель*</span>
          <template v-if="errors.ClientId && errors.ClientId.length">
            <div
              v-for="error in errors.ClientId"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
        <div
          class="form-input"
          :class="{
            'form-input--full' : form.ContractorId,
            'form-input--error' : errors.ContractorId
          }"
          @click="openDialogAddingInitialClient('ContractorId')"
        >
          <input :value="form.ContractorId && form.ContractorId.Name" type="text" readonly>
          <span class="form-input__label">Исполнитель*</span>
          <template v-if="errors.ContractorId && errors.ContractorId.length">
            <div
              v-for="error in errors.ContractorId"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
        <div
          class="form-input"
          :class="{
            'form-input--full' : form.Number,
            'form-input--error' : errors.Number
          }"
        >
          <input v-model="form.Number" type="text" required>
          <span class="form-input__label">Номер договора*</span>
          <template v-if="errors.Number && errors.Number.length">
            <div
              v-for="error in errors.Number"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
        <div class="form-input">
          <SingleDatePicker
            ref="contractDate"
            class="add-initial-contract__date-picker"
            title="Дата заключения*"
            :value="form.Date"
            @changeDates="setForm({formName: 'Date', formValue: $event.startDate})"
          />
          <template v-if="errors.Date && errors.Date.length">
            <div
              v-for="error in errors.Date"
              :key="error"
              class="form-input__error"
              >{{ error }}</div>
          </template>
        </div>
        <ContractTypeForm 
          :form="form"
          :additionalAgreementOptions="additionalAgreementOptions"
          @changeValue="changeContractTypeForm"
        />
        <div
          class="form-input"
          :class="[{'form-input--full' : form.Amount},{'form-input--error' : errors.Amount}]"
        >
          <input :value="form.Amount" @input="setForm({formName: 'Amount', formValue: $event.target.value})" type="number" required>
          <span class="form-input__label">Сумма договора</span>
          <template v-if="errors.Amount && errors.Amount.length">
            <div
              v-for="error in errors.Amount"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
        <Selector
          ref="vat"
          class="add-initial-contract__selector add-initial-contract__input form-input"
          required
          :title="'с НДС / без НДС'"
          :options="Object.keys(VatType).map((key) => ({ id: key, name: VatType[key] }))"
          :default-select-item-id="form.VatIncluded"
          :select-fitrst-item="false"
          @handleChange="(val) => setForm({formName: 'VatIncluded', formValue: val})"
        />
      </div>
      <div class="add-initial-contract__group-btns">
        <template v-if="errors.global && errors.global.length">
            <div
              v-for="error in errors.global"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
        </template>
        <div class="add-initial-contract__btns-wrapper">
          <ButtonElement
            @click-button="createInitialContract"
            text="Отправить"
            class="add-initial-contract__save-btn"
            :disabled="isBtnDisabled"
          />
          <ButtonElement
            @click-button="closeDialog"
            class="add-initial-contract__cancel-btn button--empty"
            :text="'Отменить'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import formValidate from "@/mixin/formValidate";
import Selector from "@/components/sort/Selector";
import numberWithSpaces from '@/mixin/numberWithSpaces'
import { ContractType, VatType, ContractVariant, ContractTypeKeys, AgentActingForPublisherTypeKeys } from "@/utils/contracts";
import SingleDatePicker from "@/components/sort/SingleDatePicker";
import { mapState, mapMutations, mapGetters } from 'vuex'
import { addQuery } from "@/utils/helpers"
import ContractTypeForm from "@/components/blocks/ContractTypeForm";
import moment from 'moment';

const errors = () => ({
  Number: null, 
  Date: null,
  Amount: null,
  VatIncluded: null,
  Type: null,
  SubjectType: null,
  global: []
})

export default {
  name: 'AddInitialContract',
  components: {
    ButtonElement,
    Selector,
    SingleDatePicker,
    ContractTypeForm,
  },
  mixins: [formValidate, numberWithSpaces],
  data() {
    return {
      ContractTypeKeys,
      ContractVariant,
      ContractType,
      VatType,
      errors: errors(),
      loading: false,
    }
  },
  computed: {
    ...mapState('InitialContracts', ['form', 'initialContracts']),
    ...mapGetters('Clients', ['getClientById']),
    additionalAgreementOptions() {
      return this.initialContracts.map((initialContract) => ({
        id: initialContract.Id,
        name: initialContract.Number
      }))
    },
    getClientId() {
      return this.$route.query.clientId
    },
    isBtnDisabled() {
      const alwaysRequired = this.form.Type && 
        this.form.Date && 
        this.form.ContractorId && 
        this.form.ClientId &&
        this.form.Number

      if (this.form.Type === ContractTypeKeys.SERVICE_AGREEMENT) {
        return !(alwaysRequired && this.form.SubjectType && this.form.Date)
      }
      if (this.form.Type === ContractTypeKeys.MEDIATION_CONTRACT) {
        return !(
          alwaysRequired && 
          this.form.ActionType && 
          this.form.SubjectType && 
          this.form.Date && 
          this.form.IsAgentActingForPublisher !== null
        )
      }
      if (this.form.Type === ContractTypeKeys.ADDITIONAL_AGREEMENT) {
        return !(alwaysRequired && this.form.ParentMainContractId && this.form.Date)
      }

      return !alwaysRequired
    },
  },
  methods: {
    ...mapMutations('InitialContracts', ['cleanForm', 'setForm']),
    openDialogAddingInitialClient(formName) {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddClient' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      addQuery('formName', formName, this.$route.query, this.$router)
    },
    createInitialContract() {
      this.loading = true 

      this.cleanErrors()
     
      const payload = {
        Type: this.form.Type,
        SubjectType: this.form.SubjectType,
        Number: this.form.Number || null, 
        Date: moment(this.form.Date).format('YYYY-MM-DD'),
        VatIncluded: this.form.VatIncluded === 'WITH_VAT',
        ClientId: this.form.ClientId.Id,
        ContractorId: this.form.ContractorId.Id,
        FinalContractId: this.$route.query.finalContractId,
        ActionType: this.form.ActionType,
        ParentMainContractId: this.form.ParentMainContractId,
        ...(this.form.IsAgentActingForPublisher === AgentActingForPublisherTypeKeys.RR || this.form.IsAgentActingForPublisher === AgentActingForPublisherTypeKeys.RD && { 
          IsAgentActingForPublisher: this.form.IsAgentActingForPublisher === AgentActingForPublisherTypeKeys.RR
        }),
      }

      this.$store.dispatch('InitialContracts/createInitialContract', payload).then(() => {
        this.cleanForm()
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })
        this.$notify({
          message: this.$t('new_app.success'),
          type: 'success'
        });
        
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'CheckingFinalContract' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      }).catch((err) => {
        // const objErrors = JSON.parse(err.data.errors.replace('Mediascout error request:', ''))

        const objErrors = Object.prototype.hasOwnProperty.call(err, 'Mediascout error request') ? JSON.parse(JSON.stringify(err).replace('Mediascout error request:', '')) : JSON.parse(JSON.stringify(err));

        objErrors.ErrorItems.map((errorItem) => {
          if (errorItem.PropertyName) {
            this.errors[errorItem.PropertyName] = [
              ...this.errors[errorItem.PropertyName] || [],
              errorItem.ErrorMessage,
            ]
          } else {
            this.errors.global = [...this.errors.global, errorItem.ErrorMessage]
            this.$notify({
              message: errorItem.ErrorMessage,
              type: 'error'
            })
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
    cleanErrors() {
      this.errors = errors()
    },
    closeDialog() {
      this.cleanForm()
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
    },
    changeContractTypeForm({ name, value }) {
      this.setForm({formName: name, formValue: value })
    }
  },
  mounted() {
    if (this.getClientId) {
      const client = this.getClientById(this.getClientId)
      this.setForm({formName: 'ContractorId', formValue: client })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.form-input--full .form-input__label {
    font-size: 1.2rem;
    line-height: 1.4rem;
    top: 0.6rem;
}

.add-initial-contract {
  background: #fff;
  color: #000;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__date-picker {
    width: 100%;
    background: $basicWhite;
  }

  &__input-icon {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  &__group-btns {
    display: flex;
    gap: .8rem;
    margin-top: 2rem;
    flex-direction: column;
  }

  &__btns-wrapper {
    display: flex;
    gap: .8rem;
  }

  &__save-btn {
    width: 17.4rem;
  }

  &__cancel-btn {
    width: 13.7rem;
  }
  
  
  textarea {
    font-family: 'Arial';
  }

  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 6.5rem;
    color: #000;
  }

  &__content {
    padding: 7rem;
    min-height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .selector__content {
    background: none;
    border: 1px solid #BFC2C6;
  }

  .selector__title {
    font-size: 1.6rem;
    color: #878B93;
  }
  &__input {
    .selector__content {
      width: 100%;
    }
  }

  .button {
    height: 5rem;
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      .form-input {
        width: 100%;
        max-width: 35rem;

        @include below($sm) {
          max-width: 100%;
        }
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }
  }
}

@include below(993px) {
  .add-initial-contract__title{
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 30px;
  }

  .add-initial-contract__step{
    margin-bottom: 30px;
  }

  .add-initial-contract__final,
  .add-initial-contract__content{
    padding: 15px;
  }
}

@include below(769px) {
  .add-initial-contract__final{
    .button{
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
